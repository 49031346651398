/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { AboutPage } from './pages/AboutPage/Loadable';
import { IntervenantPage } from './pages/IntervenantPage/Loadable';
import { ProgramPage } from './pages/ProgramPage/Loadable';
import { PartnerPage } from './pages/PartnerPage/Loadable';
import { RegistrationPage } from './pages/RegistrationPage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - FAPEF"
        defaultTitle="Les enjeux du developpement durable et de la santé"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content="Les enjeux du developpement durable et de la santé"
        />
      </Helmet>

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/program" component={ProgramPage} />
        <Route exact path="/intervenants" component={IntervenantPage} />
        <Route exact path="/sponsoring" component={PartnerPage} />
        <Route exact path="/registration" component={RegistrationPage} />
        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
